import { API_ENDPOINTS } from "app-constants";
import { apiService } from "./apiService";
import { AddFeatureReq, AddFeatureRes, AddRoleReq, AddRoleRes, CreateUserReq, CreateUserRes, DeleteFeatureReq, DeleteRoleReq, DeleteUserReq, GetSSMUsersRes, GetUserLearningRoleRes, GetUserRes, UpdateFeatureReq, UpdateFeatureRes, UpdateRoleReq, UpdateRoleRes, UpdateUserReq, UpdateUserRes, deleteFeatureRes, deleteRoleRes, deleteUserRes } from "types/User";
import { AddColourReq, IProductRes } from "types/Product";

function getSSMUsersService() {
    return apiService.get<GetSSMUsersRes>(
        API_ENDPOINTS.getSSMUsersList
    );
}

function getManagerService() {
    return apiService.get<GetSSMUsersRes>(
        API_ENDPOINTS.getManagerList
    );
}

function getUsersLearningRoleService() {
    return apiService.get<GetUserLearningRoleRes>(
        API_ENDPOINTS.getUsersLearningRoleList
    );
}

function getStoresByEmpIdService(empId: number) {
    return apiService.get<IProductRes>(`${API_ENDPOINTS.getstoresByEmpId}/${empId}`)
}

function getStoresByBeatIdService(empId: number) {
    return apiService.get<IProductRes>(`${API_ENDPOINTS.getstoresByBeatId}/${empId}`)
}

function getUserDetailsByEmpIdService(empId: string) {
    return apiService.get<GetUserRes>(`${API_ENDPOINTS.getUserDetails}/${empId}`)
}

function deleteUserService(empId: DeleteUserReq) {
    return apiService.delete<deleteUserRes>(
        `${API_ENDPOINTS.deleteUser}/${empId}`
    );
}

function updateUserService(data: UpdateUserReq) {
    return apiService.post<UpdateUserRes>(
        API_ENDPOINTS.updateUser,
        data
    );
}


function createUserService(data: CreateUserReq) {
    return apiService.post<CreateUserRes>(
        API_ENDPOINTS.createUser,
        data
    );
}

// Feature


function getFeatureService() {
    return apiService.get<any>(API_ENDPOINTS.getFeature);
}

function addFeatureService(data: AddFeatureReq) {
    return apiService.post<AddFeatureRes>(
        API_ENDPOINTS.addFeature,
        data
    );
}

function updateFeatureService(data: UpdateFeatureReq) {
    return apiService.post<UpdateFeatureRes>(
        API_ENDPOINTS.updateFeature,
        data
    );
}

function deleteFeatureService(featureId: DeleteFeatureReq) {
    return apiService.delete<deleteFeatureRes>(
        `${API_ENDPOINTS.deleteFeature}/${featureId}`
    );
}

function getFeatureByIdService(featureId: string) {
    return apiService.get<any>(`${API_ENDPOINTS.getFeatureById}/${featureId}`)
}

// Role


function getRoleService(data:any) {
    const queryParams = [];
    if (data?.isActive) {
        queryParams.push(`isActive=${data?.isActive}`);
    }
    let apiPath = API_ENDPOINTS.getRole;
    if (queryParams.length > 0) {
        apiPath += '?' + queryParams.join('&');
    }
    return apiService.get<any>(apiPath);
}

function addRoleService(data: AddRoleReq) {
    return apiService.post<AddRoleRes>(
        API_ENDPOINTS.addRole,
        data
    );
}

function updateRoleService(data: UpdateRoleReq) {
    return apiService.post<UpdateRoleRes>(
        API_ENDPOINTS.updateRole,
        data
    );
}

function deleteRoleService(roleId: DeleteRoleReq) {
    return apiService.delete<deleteRoleRes>(
        `${API_ENDPOINTS.deleteRole}/${roleId}`
    );
}

function getRoleByIdService(roleId: string) {
    return apiService.get<any>(`${API_ENDPOINTS.getRoleById}/${roleId}`)
}

function createUserImportService(data: CreateUserReq) {
    return apiService.post<CreateUserRes>(
        API_ENDPOINTS.createUserImport,
        data
    );
}
export {
    getSSMUsersService,
    getUsersLearningRoleService,
    getStoresByEmpIdService,
    createUserService,
    getManagerService,
    getUserDetailsByEmpIdService,
    deleteUserService,
    updateUserService,
    getFeatureService,
    addFeatureService,
    updateFeatureService,
    deleteFeatureService,
    getFeatureByIdService,
    getRoleService,
    addRoleService,
    updateRoleService,
    deleteRoleService,
    getRoleByIdService,
    getStoresByBeatIdService,
    createUserImportService
}