import React, { useEffect, useState } from "react";
// import "../../style/orderList.css";
import { ArrowLeftOutlined, EyeOutlined, FormOutlined, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "redux-store/reducer";
import { AppDispatch } from "redux-store/store";
import { getProductSchemeActions } from "redux-store/action/productAction";
// import { deleteProductSchemeService } from "services/productService";
import previousPage from "utils/previousPage";
import { useAuth } from "context/AuthContext";
import { UserRole } from "enum/common";
import { Button, Input, message, Select, Switch, Table } from "antd";
import { setLoaderAction } from "redux-store/action/appActions";
import { getActiveSchemeService, updateSchemeService } from "services/productService";
// import DeleteItem from "../common/deleteItem";

export default function ExpenseManagement() {

    const [toggleDelete, setToggleDelete] = useState(false);
    const [schemeName, setSchemeName] = useState('');
    const [schemeId, setSchemeID] = useState('');

    const productSchemeListData = useSelector(state => state?.product?.SchemeList);
    const [productSchemeList, setProductSchemeList] = useState<any[]>([]);
    const dispatch = useDispatch<AppDispatch>();
    // console.log({productSchemeList})
    const { authState } = useAuth()


    const getCollectionList = async () => {
        try {
            dispatch(setLoaderAction(true));
            const response = await getActiveSchemeService();
            dispatch(setLoaderAction(false));
            if (response && response.status === 200) {
                const { data } = response.data;
                setProductSchemeList(data);
            }
        } catch (error) {
            dispatch(setLoaderAction(false));
        }
    };
    useEffect(() => {
        if (authState?.user?.role === UserRole.SSM || authState?.user?.role === UserRole.RETAILER) {
            getCollectionList();
        } else {
            dispatch(getProductSchemeActions());
        }
    }, []);

    useEffect(() => {
        setProductSchemeList(productSchemeListData);
    }, [productSchemeListData])

    const searchStore = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = e.target;
        const FS = productSchemeListData.filter((item: any) =>
            (item?.name?.toLowerCase())?.includes(value.toLowerCase())
        );
        setProductSchemeList(FS);
    };

    const toggleHandler = (schemeId: string, name: string) => {
        setToggleDelete(true);
        setSchemeID(schemeId);
        setSchemeName(name)
    }

    const openPdfInNewTab = (file: any) => {
        window.open(file, '_blank');
    };
    const onChangeStatus = async (value: boolean, key: number) => {
        // Optimistically update the UI
        const previousData = [...productSchemeList];
        const newData = productSchemeList.map((item: any) =>
            item.id === key ? { ...item, isEnable: value } : item
        );
        setProductSchemeList(newData);
        // setIsLoadingChange(true);

        try {
            dispatch(setLoaderAction(true));
            const response = await updateSchemeService({ id: key, isEnable: value });
            if (response?.data?.status === 200) {
                message.success("Updated Successfully");
            } else {
                message.error("Something went wrong");
                setProductSchemeList(previousData); // Revert to previous state on failure
            }
        } catch (error: any) {
            message.error("Error updating status");
            setProductSchemeList(previousData); // Revert to previous state on error
        } finally {
            // setIsLoadingChange(false);
            dispatch(setLoaderAction(false));
        }
    };

    const dataSource = productSchemeList?.map((item: any, idx: any) => ({
        id: item?.id,
        key: `scheme_${idx}`,
        name: item?.name,
        monthyear: `${item?.month} - ${item?.year}`,
        status: item?.isEnable,
        view: item?.file,
        edit: <Switch onChange={(e) => onChangeStatus(e, item?.id)} checkedChildren="Enabled" unCheckedChildren="Disabled" checked={item?.isEnable} />,
    }));
    const columns: any = [
        {
            title: 'Submit By',
            dataIndex: 'submit_by',
            key: 'submit_by',
        },
        {
            title: 'Application Submit To',
            dataIndex: 'submit_to',
            key: 'submit_to',

        },
        {
            title: 'Policy Name',
            dataIndex: 'police_name',
            key: 'police_name',
        },
        {
            title: 'Claim Type',
            dataIndex: 'claim_type',
            key: 'claim_type',
        },
        {
            title: 'From Date',
            dataIndex: 'from_date',
            key: 'from_date',
        },
        {
            title: 'To Date',
            dataIndex: 'to_date',
            key: 'to_date',
        },
        {
            title: 'From Location',
            dataIndex: 'from_location',
            key: 'from_location',
        },
        {
            title: 'To Location',
            dataIndex: 'to_location',
            key: 'to_location',
        },
        {
            title: 'Total Expense',
            dataIndex: 'total_expense',
            key: 'total_expense',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            // render: (text: any, record: any) => {
            //     return <EyeOutlined className="eyeIcon" onClick={(e: any) => openPdfInNewTab(record?.view)} />

            // },
        },
        // {
        //     title: 'status',
        //     dataIndex: 'status',
        //     key: 'status',
        //     render: (text: any, record: any) => {
        //         return <span className={record?.status ? "schemeActive txtC" : "schemeInactive txtC"}>{record?.status ? "Active" : "Inactive"}</span>
        //     },
        // },
        // ...(authState?.user?.role !== UserRole.RETAILER && authState?.user?.role !== UserRole.SSM ? [{
        //     title: 'Edit',
        //     dataIndex: 'edit',
        //     key: 'edit',
        // }] : []),
        // {
        //     title: '',
        //     dataIndex: 'delete',
        //     key: 'delete',
        //     render: (text: any, record: any) => {
        //         return <Link to="#" onClick={() => toggleHandler(record?.featureId, record?.featureName)} style={{color:"red"}}><DeleteOutlined /></Link>;
        //      },
        // }, 
    ];
    return (
        <div>
            <header className="heading heading-container">
                <ArrowLeftOutlined onClick={previousPage} className="back-button" />
                <h1 className="page-title pr-18">Expenses</h1>
            </header>
            {/* {(authState?.user?.role !== UserRole.RETAILER && authState?.user?.role !== UserRole.SSM) && <Link to="/admin/add-new-scheme">
                <div className="addIcon">
                    <PlusOutlined className="plusIcon" />
                </div>
            </Link>} */}
            <main className='content' style={{ marginBottom: "0px" }}>
            <div className="target-filter ">
            <div className="category"  >
            <span style={{ color: "black", fontSize: "14px", fontWeight: "bold" }}>Select User</span>
           {/* {authState?.user?.role === UserRole.SSM || authState?.user?.role === UserRole.RETAILER ?  */}
           <Select
           placeholder="Select Executive"
        //    onChange={handleExecutiveChange}
        //    defaultValue={`${authState?.user?.name} (${authState?.user?.role})`} 
           options={
            []
         }
         className='selectTargetFil'
         />
           
            
          </div>
          <div className="brand" style={{ paddingLeft: "0px" }}>
            <span style={{ color: "black", fontSize: "14px", fontWeight: "bold" }}>From</span>
              <Input type="date"/>
              {/* <Cascader defaultValue={['Year', String(currentYear)]} options={options}  placeholder="Please select"  onChange={handleTimelineChange}  /> */}
            
          </div>
          <div className="brand" style={{ paddingLeft: "0px" }}>
            <span style={{ color: "black", fontSize: "14px", fontWeight: "bold" }}>To </span>
              <Input type="date"/>
              {/* <Cascader defaultValue={['Year', String(currentYear)]} options={options}  placeholder="Please select"  onChange={handleTimelineChange}  /> */}
            
          </div>
        
          <div className="category"  >
            <span style={{ color: "black", fontSize: "14px", fontWeight: "bold" }}>Select Status </span>
           {/* {authState?.user?.role === UserRole.SSM || authState?.user?.role === UserRole.RETAILER ?  */}
           <Select
           placeholder="Select"
        //    onChange={handleExecutiveChange}
        //    defaultValue={`${authState?.user?.name} (${authState?.user?.role})`} 
           options={
            []
         }
         className='selectTargetFil'
         />
           
            
          </div>
          <div className="category" >
            <Button style={{background:"#6164A6", color:"white"}} >
                Search
                </Button>
            
          </div>
          <div className="category"  >
            {/* <span style={{ color: "black", fontSize: "14px", fontWeight: "bold" }}>Select Status </span> */}
          
            <Button >
                Clear
                </Button>
            
          </div>
          <div className="category"  >
            {/* <span style={{ color: "black", fontSize: "14px", fontWeight: "bold" }}>Select Status </span> */}
        
            
          </div>
        </div>
                    
                </main>
            <main style={{ marginTop: "10px" }}>
                <div className="searchproduct">
                    <div>
                        <main className='content'>
                            <Table

                                dataSource={
                                    []
                                }
                                bordered
                                columns={columns}
                                size="small"
                                pagination={false}
                            />
                        </main>
                    </div>
                </div>
            </main>
        </div>
    );
}
