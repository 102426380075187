import React, { useEffect, useState } from "react";
// import "../../style/orderList.css";
import { ArrowLeftOutlined, EyeOutlined, FormOutlined, PlusOutlined } from "@ant-design/icons";
import {  Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "redux-store/reducer";
import { AppDispatch } from "redux-store/store";
import { getProductSchemeActions } from "redux-store/action/productAction";
// import { deleteProductSchemeService } from "services/productService";
import previousPage from "utils/previousPage";
import { useAuth } from "context/AuthContext";
import { UserRole } from "enum/common";
import { Button, Col, Input, message, Row, Select, Switch, Table, Form } from "antd";
import { setLoaderAction } from "redux-store/action/appActions";
import { getActiveSchemeService, updateSchemeService } from "services/productService";
import HookFormSelectField from "component/HookFormSelectField";
import { yupResolver } from "@hookform/resolvers/yup";
import { beatSchema } from "utils/formValidations";
import { useForm } from "react-hook-form";
import HookFormInputField from "component/HookFormInputField";
// import DeleteItem from "../common/deleteItem";

export default function ExpenseApply() {

    const [toggleDelete, setToggleDelete] = useState(false);
    const [schemeName, setSchemeName] = useState('');
    const [schemeId, setSchemeID] = useState('');

    const productSchemeListData = useSelector(state => state?.product?.SchemeList);
    const [productSchemeList, setProductSchemeList] = useState<any[]>([]);
    const dispatch = useDispatch<AppDispatch>();
    // console.log({productSchemeList})
    const { authState } = useAuth()


    const getCollectionList = async () => {
        try {
            dispatch(setLoaderAction(true));
            const response = await getActiveSchemeService();
            dispatch(setLoaderAction(false));
            if (response && response.status === 200) {
                const { data } = response.data;
                setProductSchemeList(data);
            }
        } catch (error) {
            dispatch(setLoaderAction(false));
        }
    };
    useEffect(() => {
        if (authState?.user?.role === UserRole.SSM || authState?.user?.role === UserRole.RETAILER) {
            getCollectionList();
        } else {
            dispatch(getProductSchemeActions());
        }
    }, []);

    useEffect(() => {
        setProductSchemeList(productSchemeListData);
    }, [productSchemeListData])

    const searchStore = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = e.target;
        const FS = productSchemeListData.filter((item: any) =>
            (item?.name?.toLowerCase())?.includes(value.toLowerCase())
        );
        setProductSchemeList(FS);
    };

    const toggleHandler = (schemeId: string, name: string) => {
        setToggleDelete(true);
        setSchemeID(schemeId);
        setSchemeName(name)
    }

    const openPdfInNewTab = (file: any) => {
        window.open(file, '_blank');
    };
    const onChangeStatus = async (value: boolean, key: number) => {
        // Optimistically update the UI
        const previousData = [...productSchemeList];
        const newData = productSchemeList.map((item: any) =>
            item.id === key ? { ...item, isEnable: value } : item
        );
        setProductSchemeList(newData);
        // setIsLoadingChange(true);

        try {
            dispatch(setLoaderAction(true));
            const response = await updateSchemeService({ id: key, isEnable: value });
            if (response?.data?.status === 200) {
                message.success("Updated Successfully");
            } else {
                message.error("Something went wrong");
                setProductSchemeList(previousData); // Revert to previous state on failure
            }
        } catch (error: any) {
            message.error("Error updating status");
            setProductSchemeList(previousData); // Revert to previous state on error
        } finally {
            // setIsLoadingChange(false);
            dispatch(setLoaderAction(false));
        }
    };

    const dataSource = productSchemeList?.map((item: any, idx: any) => ({
        id: item?.id,
        key: `scheme_${idx}`,
        name: item?.name,
        monthyear: `${item?.month} - ${item?.year}`,
        status: item?.isEnable,
        view: item?.file,
        edit: <Switch onChange={(e) => onChangeStatus(e, item?.id)} checkedChildren="Enabled" unCheckedChildren="Disabled" checked={item?.isEnable} />,
    }));
    const columns: any = [
        {
            title: 'Submit By',
            dataIndex: 'submit_by',
            key: 'submit_by',
        },
        {
            title: 'Application Submit To',
            dataIndex: 'submit_to',
            key: 'submit_to',

        },
        
       
        {
            title: 'From Date',
            dataIndex: 'from_date',
            key: 'from_date',
        },
        {
            title: 'To Date',
            dataIndex: 'to_date',
            key: 'to_date',
        },
        {
            title: 'Policy Type',
            dataIndex: 'police_type',
            key: 'police_type',
        },
        {
            title: 'Total Expense',
            dataIndex: 'total_expense',
            key: 'total_expense',
        },
        {
            title: 'Distance in KM.',
            dataIndex: 'distance',
            key: 'distance',
        },
       
       
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Remark',
            dataIndex: 'remark',
            key: 'remark',
            // render: (text: any, record: any) => {
            //     return <EyeOutlined className="eyeIcon" onClick={(e: any) => openPdfInNewTab(record?.view)} />

            // },
        },
        // {
        //     title: 'status',
        //     dataIndex: 'status',
        //     key: 'status',
        //     render: (text: any, record: any) => {
        //         return <span className={record?.status ? "schemeActive txtC" : "schemeInactive txtC"}>{record?.status ? "Active" : "Inactive"}</span>
        //     },
        // },
        // ...(authState?.user?.role !== UserRole.RETAILER && authState?.user?.role !== UserRole.SSM ? [{
        //     title: 'Edit',
        //     dataIndex: 'edit',
        //     key: 'edit',
        // }] : []),
        // {
        //     title: '',
        //     dataIndex: 'delete',
        //     key: 'delete',
        //     render: (text: any, record: any) => {
        //         return <Link to="#" onClick={() => toggleHandler(record?.featureId, record?.featureName)} style={{color:"red"}}><DeleteOutlined /></Link>;
        //      },
        // }, 
    ];
    const {
        control,
        setValue,
        handleSubmit,
      } = useForm({
        mode: "all",
        resolver: yupResolver(beatSchema),
        defaultValues: {
            
        }
      })
      const onFinish = (values: any) => {
        console.log('Success:', values);
    };
    return (
        <div>
            <header className="heading heading-container">
                <ArrowLeftOutlined onClick={previousPage} className="back-button" />
                <h1 className="page-title pr-18">Apply Expense</h1>
            </header>
            <main className=''>
        <Form
          className="add-store-form createbeat"
          onFinish={onFinish}
          autoComplete="off" 
          style={{height:"auto"}}

          // layout="horizontal"
          // labelCol={{ span: 2 }}
          >
          {/* <HookFormInputField
            control={control}
            type="text"
            name="beatName"
            placeholder="Enter Beat name"
            label={"Beat Name"}
            required
          /> */}
          {/* <HookFormInputField
            control={control}
            type="text"
            name="area"
            placeholder="Search Area"
            label={"Area"}
            required
          /> */}
           <HookFormSelectField
            control={control}
            type="text"
            name="beatType"
            placeholder="Select Policy"
            label={"Select Policy"}
            showSearch
            allowClear
            // defaultValue={userRoleFilter[0]?.role ?? ""}
            // callback={beatTypeHandler}
            optionData={[]
              // [
              //   {label:"Sales Rep", value: UserRole.SSM},
              //   {label:"Channel", value: UserRole.CHANNEL},
              //   {label:"Retailer", value: UserRole.RETAILER}
              // ]
            }
            filterOption={(inputValue: any, option: any) => {
              return option.label.toLowerCase().includes(inputValue.toLowerCase())
            }}
            required
          />
         
         
         <Row className="mt-4">
            <Col span={12} className="pr-12">
            <HookFormInputField
            control={control}
            type="date"
            name="area"
            placeholder="Date"
            label={"Date"}
            // required
          />
            </Col>
            <Col span={12} className="pl-12">
            <HookFormInputField
                control={control}
                type="number"
                name="country"
                placeholder="Total Expense"
                label={"Total Expense"}
                required
              />
            </Col>
          </Row>
              <Row className="mt-4">
            <Col span={12} className="pr-12">
            <HookFormInputField
                control={control}
                type="text"
                name="country"
                placeholder="Start Location"
                label={"Location"}
                // required
              />
            </Col>
            <Col span={12} className="pl-12">
            <HookFormInputField
                control={control}
                type="file"
                name="country"
                placeholder="Upload Document"
                label={"Upload Document"}
                // required
              />
            </Col>
          </Row>
          <Row className="mt-4">
            {/* <Col span={12} className="pr-12">
            Total Expense
            </Col> */}
            <Col span={12} className="pl-12">
            <HookFormInputField
           control={control}
           type="textarea"
           name="city"
           placeholder="Enter Expense Details"
           label={"Details"}
         />
              {/* { cityData === "Others" ?
           <HookFormInputField
           control={control}
           type="text"
           name="city"
           placeholder="Enter Town/City"
           label={"Town/City"}
           required
           callback={getCitySelect}
         />
         :
                <HookFormSelectField
                control={control}
                type="text"
                name="city"
                placeholder="Select city"
                label={"City"}
                showSearch
                optionData={ 
                  city?.map((data: any) => ({
                    label: data?.name,
                    value: data?.name
                  })) || []
                }
            
                filterOption={(inputValue: any, option: any) => {
                  return option.label.toLowerCase().includes(inputValue.toLowerCase())
                }}
                callback={getCitySelect}
                
              />
              }  */}
            </Col>
          </Row>
          {/* <HookFormSelectField
            control={control}
            type="text"
            name="store"
            placeholder="Select Store"
            label={"Add Store"}
            showSearch
            mode="multiple"
            allowClear={true}
            defaultValue={[]}
            optionData={
              []
            }
            required
            // onChange={handleResetStore}
          /> */}
          <div className="beat-btn">
            <div
              className=" orders-btn">
              <Button 
            //   onClick={() => redirect(-1)}
              >Cancel</Button>
              <button type="submit" className="btn-save">
                Save
              </button>
            </div>
          </div>
        </Form>
      </main>
            <main style={{ marginTop: "10px" }}>
                <div className="searchproduct">
                    <div>
                        <main className='content'>
                            <Table

                                dataSource={
                                    []
                                }
                                bordered
                                columns={columns}
                                size="small"
                                pagination={false}
                            />
                        </main>
                    </div>
                </div>
            </main>
        </div>
    );
}
